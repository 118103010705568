import React, { useState, useRef } from "react";
import "./dynamicCheckService.css";
import "react-phone-number-input/style.css";
import { Dialog, DialogTitle } from "@mui/material";
import { checkImei } from "../../_services/unlockPhone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DynamicCheckServiceInfo = () => {
  const { t } = useTranslation();
  const { checkService } = useParams(); // Get dynamic route parameter
  const [openDialog, handleDisplay] = useState(false);
  const [info, setInfo] = useState(null);
  const { startLoading, stopLoading } = useLoading();
  const [valid_token, setValidToken] = useState([]);
  const captchaRef = useRef(null);

  const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
  const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const Imei = Yup.object().shape({
    imei: Yup.string()
      .required(t('IEMIE1'))
      .test("isValidIMEI", t('IEMIE2'), (value) => isValidIMEI(value)),
  });

  const isValidIMEI = (imei) => {
    if (/^\d{15}$/.test(imei)) {
      const imeiDigits = imei.split("").map(Number);
      const checksum = imeiDigits.pop();
      const sum = imeiDigits.reduce((acc, digit, index) => {
        const doubledDigit = index % 2 === 0 ? digit : digit * 2;
        return acc + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit);
      }, 0);
      return (10 - (sum % 10)) % 10 === checksum;
    }
    return false;
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let token = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (token) {
        startLoading();
        let valid_token = await verifyToken(token);
        setValidToken(valid_token);

        if (valid_token[0].success === true) {
          const res = await checkImei(values.imei);
          if (res) {
            setInfo(res);
            openDialogBox();
            resetForm();
          } else {
            console.error("Unexpected response format:", res);
          }
        } else {
          toast.error("Sorry!! Verify you are not a bot");
        }
      } else {
        toast.error("Sorry!! Verify you are not a bot");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error('Something went wrong');
    } finally {
      stopLoading();
    }
  };

  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await Axios.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });
      APIResponse.push(response["data"]);
      toast.success(t('IEMIS1'));
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };

  // Dynamic data rendering based on CheckService parameter
  const data = {};

  switch (checkService) {
    case "premium-blacklist-checker":
      data.url = 'premium-blacklist-checker';
      data.title = 'Premium blacklist check';
      data.subtitle = "Verify if your phone is blacklisted or clean using its IMEI number. Ensure your device is not blacklisted due to loss, theft, or unpaid bills.";
      break;
    case "apple-full-info-check-gsx":
      data.url = 'apple-full-info-check-gsx';
      data.title = 'Apple full info check (GSX Report)';
      data.subtitle = "The iPhone GSX (Global Service Exchange) Report provides detailed information about an iPhone's status and history, including warranty details, repairs, and technical issues. It helps verify device authenticity and assess its condition for repairs or purchases.";
      break;
    case "samsung-account-removal":
      data.url = 'samsung-account-removal';
      data.title = 'Samsung Account Removal (FRP)';
      data.subtitle = "Remove your Samsung account effortlessly to regain full control.";
      break;
    // Add more cases for other services
    default:
      data.url = 'default-service';
      data.title = 'Default Service';
      data.subtitle = "This is a default service description.";
  }
  console.log(data)
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-5 pb-0 position-relative">
        <div className="container py-md-5">
          <div className="row justify-content-center p-3 success-section">
            <a href="/premium-blacklist-checker" className="text-dark text-decoration-none"><i class="fa fa-chevron-left me-2 text-dark"></i>Back</a>
            <div className="col-12 col-md-7">
              <h1 className="fw-bold display-5 text-center mt-md-3">
              {t("Prebch1")}
              </h1>
              <h5>{t("Prebcp1")}</h5>
            </div>

            <div className="row justify-content-center pt-3 pt-md-5 px-0">
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                 <h5 className="mb-0">IMEI</h5>
                 <h5 className="mb-0 fw-bold">8539375828501345</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                 <h5 className="mb-0">Blacklisted by</h5>
                 <h5 className="mb-0 fw-bold">Hutchison 3G UK Ltd</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                 <h5 className="mb-0">Blacklisted on</h5>
                 <h5 className="mb-0 fw-bold">2024-04-11 20:13:33</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                 <h5 className="mb-0">Blacklisted reason</h5>
                 <h5 className="mb-0 fw-bold">Stolen Or Lost (0011)</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                 <h5 className="mb-0">Blacklisted status</h5>
                 <h5 className="mb-0 fw-bold text-danger">Blacklisted</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-center mt-4 px-0">
              <button className="btn rounded  py-3 px-md-4 px-2 me-md-2 me-1 need-support1" type="button">iCloud Unlock</button><button className="btn rounded py-3 px-md-4 px-2 ms-md-2 ms-1 need-supports" type="button">Carrier Unlock</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DynamicCheckServiceInfo;
