import React, { useState, useEffect } from "react";
import "./Videos.css";
import cookies from "js-cookie";

export const Videos = () => {
  const [videoEmbedId, setVideoEmbedId] = useState("");
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  const [key, setKey] = useState(0); // Use a state variable to force re-render

  // Function to update language and re-render when the language changes
  const updateLanguageAndRerender = () => {
    const newLanguageCode = cookies.get("i18next") || "en";
    if (newLanguageCode !== currentLanguageCode) {
      setCurrentLanguageCode(newLanguageCode);
      // Changing the key to force re-render
      setKey((prevKey) => prevKey + 1);
    }
  };

  useEffect(() => {
    // Re-run this effect if the language changes
    updateLanguageAndRerender();

    // Optional: set an interval to detect language changes if the language can change dynamically
    const intervalId = setInterval(() => {
      updateLanguageAndRerender();
    }, 1000); // Poll every second for language change

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentLanguageCode]);

  // Update the video embed ID based on the current language
  useEffect(() => {
    if (currentLanguageCode === "en") {
      setVideoEmbedId("8pgmaamqci");
      console.log("English video loaded");
    } else {
      setVideoEmbedId("hakli95f9n");
      console.log("Spanish video loaded");
    }
  }, [currentLanguageCode]);

  return (
    <div key={key}> {/* Changing the key value forces a full re-render */}
      <div className="top-seprator"></div>
      <section className="videos-slider py-md-5 pt-5 pb-0">
        <div className="container pb-5">
          <h2 className="text-center display-4 fw-bold mb-5 check-out-video">
            {currentLanguageCode === "en"
              ? "Their words give us satisfaction"
              : "Mira estos vídeos"}
          </h2>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
            key={key}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}
            >
              <div
                className={`wistia_embed wistia_async_${videoEmbedId} seo=true videoFoam=true`}
                style={{ height: "100%", position: "relative", width: "100%" }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: "100%",
                    left: 0,
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    top: 0,
                    transition: "opacity 200ms",
                    width: "100%",
                  }}
                >
                  <img
                    src={`https://fast.wistia.com/embed/medias/${videoEmbedId}/swatch`}
                    style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }}
                    alt="Video thumbnail"
                    aria-hidden="true"
                    onLoad={(e) => (e.target.parentNode.style.opacity = 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Videos;
