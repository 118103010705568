import React from "react";
import { useTranslation } from "react-i18next";

const CodeEntryInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="banner py-5">
        <div className="container py-md-5">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-8">
              <div className="reseller-program">
                <h3>
                  <strong>{t('ceih1')}</strong>
                </h3>
                <p>
                {t('ceip1')}
                </p>
                <p>
                {t('ceip2')}
                </p>
                <p>{t('ceip4')}</p>
                <ul>
                  <li>{t('ceip5')}
                  </li>
                  <li>
                  {t('ceip6')}
                  </li>
                  <li>
                  {t('ceip7')}
                  </li>
                </ul>

                <p>{t('ceip8')}</p>
                <ul style={{"border-bottom": "1px solid", 'padding-bottom': "20px"}}>
                  <li className="list-type-none">
                  {t('ceip9')}
                  </li>
                </ul>

                <h3>
                  <strong>{t('ceih2')}</strong>
                </h3>
                <p>{t('ceip10')}</p>
                <p>
                {t('ceip11')}
                </p>
                <p>{t('ceip12')}</p>
                <ul>
                  <li>
                  {t('ceip13')}
                  </li>
                  <li>
                  {t('ceip14')}
                  </li>
                  <li>
                  {t('ceip15')}
                  </li>
                </ul>

                <p>{t('ceip16')}</p>
                <ul style={{"border-bottom": "1px solid", 'padding-bottom': "20px"}}>
                  <li className="list-type-none">
                  {t('ceip17')}
                  </li>
                </ul>

                <h3>
                  <strong>{t('ceih3')}</strong>
                </h3>
                <p>{t('ceip18')}</p>
                <p>
                {t('ceip19')}
                </p>
                <p>{t('ceip20')}</p>
                <ul>
                  <li>{t('ceip21')}</li>
                  <li>{t('ceip22')}.</li>
                  <li>
                  {t('ceip23')}
                  </li>
                </ul>

                <p>{t('ceip24')}</p>
                <ul style={{"border-bottom": "1px solid", 'padding-bottom': "20px"}}>
                  <li className="list-type-none">
                  {t('ceip25')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CodeEntryInstructions;
