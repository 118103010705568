import React from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const TermsandConditions = ({ col }) => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const propClass = col === 10 ? "col-12 px-4" : "col-md-10 px-4";
  return (
    <>
      <section className="">
        <div className="container py-md-2 pt-2">
          <div className="row py-md-5 justify-content-center">
            <div className={`${propClass}`}>
              <div className="term-condition">
                  <h2 className="pt-md-4 pb-md-2 py-3 tc-head fw-bold">{t("tch1")}</h2> <p className="tc-text my-md-4 my-2">{t("tcp1")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch2")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp2")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch3")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp3")}</p>

                    <h5 className="tc-title fw-bold">{t("tch4")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp4")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch5")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp5")}</p>
                                        
                    <h5 className="tc-title fw-bold">{t("tch6")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp6")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch7")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp7")}</p>

                    <h5 className="tc-title fw-bold">{t("tch8")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp8")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch9")}</h5> <p className="tc-text my-md-4 my-2"> {t("tcp9")}</p>
                    
                    <h5 className="tc-title fw-bold">{t("tch10")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp10")}</p>
                    
                  {  currentLanguageCode==='es'&&
                    <>
                    <h5 className="tc-title fw-bold">{t("tch11")}</h5> <p className="tc-text my-md-4 my-2">{t("tcp11")}</p>
                  </>
                  }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
