import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CheckOut = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const productDataString = queryParams.get("productData");
    const formValueString = queryParams.get("formValue");

    try {
      // Decode and parse data only if query parameters exist
      const productData = productDataString ? JSON.parse(decodeURIComponent(productDataString)) : null;
      const formValue = formValueString ? JSON.parse(decodeURIComponent(formValueString)) : null;

      // Navigate to review-order route with state
      navigate('/review-order', { state: { formValue, productData } });

      // Store in localStorage as a backup
      localStorage.setItem("state", JSON.stringify({ formValue, productData }));
    } catch (error) {
      console.error("Error parsing query parameters:", error);
    }
  }, [search, navigate]);

  return (
    <div>Loading...</div>
  );
};

export default CheckOut;
