import React,{useState,useEffect} from "react";
import { useTranslation } from "react-i18next";
import BlogServices from "../../_services/blogService"; 
import './blogs.css'
import { Link ,useNavigate} from "react-router-dom";

const Blogs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [limit, setLimit] = useState(9);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await BlogServices.getAllBlogs(limit, 1);
        console.log(res);
        setBlogs(res);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    const formattedDate = formatDate("2024-04-29T20:58:19.665+00:00");
    console.log(formattedDate); // Output: "Fri, Apr 29, 2024"
    fetchData();
  }, []); // Pass an empty dependency array
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: 'short', // Short weekday name (e.g., "Tue")
      month: 'short',   // Short month name (e.g., "Sep")
      day: '2-digit',   // Two-digit day of the month (e.g., "22")
      year: 'numeric'   // Full numeric year (e.g., "2021")
    };
    return date.toLocaleDateString('en-US', options);
  };
  
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const LoadMore=async ()=>{
    try {
      setLimit(limit+9)
      const res = await BlogServices.getAllBlogs(limit, 1);
      console.log(res);
      setBlogs(res);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  }
  return (
    <>
<section className="banner pt-5 pb-0 blogs">
    <div className="container pt-md-5 pb-0 pt-5">
        <div className="row">
                <div className="col-12 col-md-9 d-flex bg-white p-4 rounded-top">
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <img className="img-fluid blog-feature"  src="/images/blog1.jpg" alt="" />
                      <p className="date">TUE, Sep 22, 2021</p>
                    </div>
                    <div className="col-12 col-md-7 px-3 py-0">
                      <h3 className="fs-2 py-0 fw-semibold">The Science of Laughter: Why Humor Makes Us Feel Good</h3>
                      <p className="blog-description py-3">Explore the psychological and physiological reasons behind why humor and laughter have ...</p>
                      <p className="mt-md-5 mb-0">Read more <i class="fa fa-arrow-right"></i></p>
                    </div>
                  </div>
                </div>
        </div>
    </div>
</section>
<section className="py-5 blogs-list">

      <div className="container py-md-5 py-0">
        <div className="row">
          {blogs.map((blog, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-3 mb-md-5">
              <Link to={`/blog-details/${blog._id}`} onClick={scrollToTop}>
                <img className="img-fluid blog-feature"  src={`${process.env.REACT_APP_API_URL1}/${blog?.imgUrl}`} alt={blog?.blogTitle} />
                <p className="date">{formatDate(blog.createdAt)}</p>
                <h3 className="blog-title py-3 fw-semibold">{blog.blogTitle}</h3>
                <p className="blog-description">{JSON.parse(blog.fields)[0].description}</p>
              </Link>
            </div>
          ))}
      <div className="col-12 text-center">
          <button className="btn load-more-btn fw-bold mb-2" onClick={LoadMore}>{t('blog4')}</button>  
      </div>
            
    </div>
  </div>
</section>


    </>
  );
};

export default Blogs;
