import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState("en");
  const { i18n } = useTranslation();
  const handleLanguageChange = (lang) => {
    Cookies.set("i18next", lang, {
      sameSite: "None",
      secure: true,
    });
    i18n.changeLanguage(lang);
    setLang(lang);
  };
  useEffect(() => {
    const lang = Cookies.get("i18next");
    const removeRegion = (langCode) => {
      const updatedLang = langCode?.split("-")[0];
      return updatedLang;
    };
    const updatedLang = removeRegion(lang);
    setLang(updatedLang);
    Cookies.set("i18next", updatedLang, {
      sameSite: "None",
      secure: true,
    });
  }, [lang]);
  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang,
        handleLanguageChange,       
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
