import React, { useState, useCallback } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../_shared/LoadingContext";
import { useTranslation } from "react-i18next";
import "./CheckoutForm.css";

const CheckServiceCheckout = ({ clientSecret, formValue }) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [agree, setAgree] = useState(false);

  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const isNameValid = useCallback(() => {
    if (name.trim() === "") {
      setNameError(t("ContE1"));
      return false;
    }
    setNameError("");
    return true;
  }, [name, t]);

  const handlePaymentResult = useCallback(async (result, status) => {

    const apiResponse = await
      fetch(`${process.env.REACT_APP_API_URL}/checkService/addCheckServiceOrder`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orderId: result?.paymentIntent?.id || clientSecret,
          clientSecret,
          status,
        }),
      });

    const responseResult = await apiResponse.json();
    const { orderId } = responseResult;
    orderId['customerEmail'] = orderId?.email
    orderId['product'] = {delivery_time:'0-1 hrs'}
    if (orderId.status === "succeeded") {
      toast.success(responseResult.message);
      localStorage.setItem("order", JSON.stringify({ order: orderId }));
      navigate("/success");
    } else {
      navigate("/unlock-phone");
    }
  }, [clientSecret, navigate]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !isNameValid()) return;

    startLoading();

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name,
              email: formValue.email,
            },
          },
        },
        redirect: "if_required",
      });

      if (result.error) {
        toast.error(result.error.message);
        await handlePaymentResult(result, "Failed");
      } else {
        await handlePaymentResult(result, "succeeded");
      }
    } catch (error) {
      toast.error("An error occurred during payment. Please try again.");
      console.error("Payment submission error:", error);
    } finally {
      stopLoading();
    }
  }, [stripe, elements, isNameValid, name, formValue.email, handlePaymentResult, startLoading, stopLoading]);

  return (
    <>
      <form>
        <div>
          <input
            type="text"
            className="w-100 p-3 border rounded"
            placeholder={t("UnpF1")}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <div className="error">{nameError}</div>}
        </div>
        <PaymentElement />
        <button
          type="submit"
          className="btn text-white border-none w-100 rounded px-5 py-2 mt-3 cus-payment-btn"
          style={{ background: "#6A5696" }}
          disabled={agree}
          onClick={handleSubmit}
        >
          {t("UnpCP3")}
        </button>
      
        <ToastContainer />
      </form>
     
    </>
  );
};

export default CheckServiceCheckout;
