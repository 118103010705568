// apiService.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL; // replace with your API URL

export const fetchData = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(error.message);
  }
};


export const postData = async (endpoint, body, options = {}) => {
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, body, {
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      ...options,
    });

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const requestRefund = async (body) => {
  try {
    const response = await axios.post(`${apiUrl}/requestRefund`, body);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
