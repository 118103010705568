import React, { useState, useEffect } from "react";
import "./imei-blacklist.css";
import { imeiHistory } from "../../_services/unlockPhone";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
const ImeiStatus = () => {
  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const [info, setInfo] = useState(null);
  const [imgurl, setImgurl] = useState(null);
  const location = useLocation();
  const { email } = location.state || '';
  useEffect(() => {
    startLoading();
    setTimeout(() => {
      const imeiInfo = Cookies.get("imeiInfo");
      if (imeiInfo) {
        const fetchData = async () => {
          const parsedData = JSON.parse(imeiInfo);
          try {
            const res = await imeiHistory(parsedData.history_id,email);
            console.log(res);
            if (res) {
              setInfo(res?.responseBody?.result);
              // setImgurl(res?.devices[0]?.img);
              console.log(info);
            }
          } catch (error) {
            console.error("Error fetching blogs:", error);
            toast.error(error.message);
          } finally {
            stopLoading();
          }
        };
        fetchData();
      } else {
      }
    stopLoading();
    }, 3000);
  }, []);

  const downloadPdfDocument = async () => {
    const input = document.getElementById("download");
    html2canvas(input, {
      scale: 1,
      imageTimeout: 0,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1);
      const pdf = new jsPDF("p", "mm", "a4", 750, 1080);
      pdf.addImage(imgData, "JPEG", 20, 10);
      pdf.save(`imei-info.pdf`);
    });
  };
  return (
    <>
      {info && (
        <div>
          <section className="banner-ImeiBlacklist ss py-md-2 pt-2 pt-md-5 pb-0 position-relative">
            <div className="container py-md-5 pt-2 pb-0">
              <div className="row pt-5">
                <div className="col-md-5 col-12 px-md-0 px-4 left_img">
                  <div className="d-block imei-status-block rounded px-4 py-3 mb-2">
                    <p className="imei-status mb-0">IMEI</p>
                    <p className="imei-status-value mb-0">{info?.IMEI}</p>
                  </div>
                  <div className="d-block imei-status-block rounded px-4 py-3 mb-2">
                    <p className="imei-status mb-0">Brand:</p>
                    <p className="imei-status-value mb-0">
                      {info?.Manufacturer}
                    </p>
                  </div>
                  <div className="d-block imei-status-block rounded px-4 py-3 mb-2">
                    <p className="imei-status mb-0">Phone model:</p>
                    <p className="imei-status-value mb-0">
                      {info["Model Name"]}
                    </p>
                  </div>
                  {/* <img className="img-fluid pt-3" src={imgurl} alt="" /> */}
                </div>
                <div className="col-md-7 col-12 px-0">
                  <div className="ImeiBlacklist-information bg-white rounded ms-md-5 ms-0 p-md-5 p-4">
                    <div id="download">
                      <h2 className="tc-title fw-bold mb-md-4 mb-2">
                        {" "}
                        IMEI Information
                      </h2>
                      {info &&
                        Object.entries(info)?.map(([key, value]) => (
                          <div
                            key={key}
                            className="row d-flex align-items-center justify-content-between py-4"
                          >
                            <div className="col-12 col-md-6">
                              <h3 className="device-type">{key}</h3>
                            </div>
                            <div className="col-12 col-md-6 device-value-block text-end">
                              <h3
                                className={`device-value fw-semibold ${
                                  value === "BLACKLISTED" ? "x" : "y"
                                }`}
                              >
                                {value}
                              </h3>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-12 col-md-12 device-value-block text-center">
                      <button
                        className="btn border fw-semibold rounded text-dark py-3 px-5 me-2 need-support"
                        type="button"
                        onClick={downloadPdfDocument}
                      >
                        Print Info{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="top-checker py-5">
            <div className="container">
              <div className="row py-4">
                <h2 className="display-6 text-white fw-bold my-md-4 my-2">
                  Top Checker{" "}
                </h2>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">
                      Carrier & Lock Status & FMI
                    </h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">Phone Blacklist Check PRO</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">
                      Find My iPhone & iCloud Status
                    </h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
              </div>

              <div className="row py-4">
                <h2 className="display-6 text-white fw-bold my-md-4 my-2">
                  BASIC CHECKS (FREE)
                </h2>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">
                      Phone Blacklist Check Simple
                    </h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">Generate Random IMEI</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">eSim Compatibility</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">Check Serial Number</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
              </div>

              <div className="row py-4">
                <h2 className="display-6 text-white fw-bold my-md-4 my-2">
                  ADVANCED CHECKS (PAID)
                </h2>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">Sim Lock Status Check</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">Sold-By & Coverage</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">iPhone Unlock</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">
                      Verizon USA Financial Status Check
                    </h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">
                      T-Mobile USA Financial Status Check
                    </h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-4">
                  <div className="d-flex align-items-center justify-content-between px-3 pt-4 pb-4 top-checker-block">
                    <h3 className="checker-text">iCloud Unlock</h3>
                    <i className="fa fa-chevron-right checker-icon"></i>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ImeiStatus;
