import React from "react";
import { useTranslation } from "react-i18next";
const AffiliatResellerHub = () => {
  const { t } = useTranslation();

  return (
    <>
    <section className="banner py-3 About-page">
        <div className="container py-md-2 pt-5">
          <div className="row py-md-5 justify-content-center align-items-center pt-4 ptmd-3">
            <div className="col-12 col-md-5 px-4">
              <h1 className="fw-semibold display-4 balance">
              {t('affRBh1')}
              </h1>
              <p className=" imeiblacklist balance">
              {t('affRBp1')}
              </p>
              <button class="btn px-4 check-now-btn fw-semibold mb-0">{t('affRBb1')}</button>
            </div>
            <div className="col-12 col-md-5 px-4 text-center">
              <img className="img-fluid" src="/images/aboutbanner.png" width={'400'} />
            </div>
          </div>
        </div>
      </section>
      <section className="affiliate pb-md-5">
        <div className="container py-md-2 pt-2 px-4">
          <div className="row pt-md-5">
            <div className="col-12">
                  <h2 className="tc-head fw-semibold py-3">{t('affResellerH1')}</h2>
                  <p className="tc-text my-2">{t('affResellerP0')}</p>
                  <button className="btn btn-lg px-3 send-request-btn">{t('affResellerB1')}</button>  

                  <img className="img-fluid pt-md-5 pt-5 pb-3" src="/images/reseller1.png" alt="reseller" />
                  <p className="tc-text my-0">{t('affResellerP1')}</p>
                  <h4 className="tc-title fw-semibold pt-md-4 pt-2">{t('affResellerH2')}</h4>

                  <ul className="py-2 ps-4">
                    <li className="py-1 my-1 tc-text">{t('affResellerP2')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP3')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP4')}</li>
                </ul>
            </div>

            <div className="col-12 pt-3">
                  <h2 className="tc-head fw-semibold py-3">{t('affResellerH3')}</h2>
                  <p className="tc-text my-2">{t('affResellerP5')}</p>
                  <button className="btn btn-lg px-3 send-request-btn">{t('affResellerB2')}</button>  

                  <img className="img-fluid pt-md-5 pt-5 pb-3" src="/images/reseller2.png" alt="reseller" />
                 
                  <h4 className="tc-title fw-semibold pt-md-4 pt-2">{t('affResellerH2')}</h4>

                  <ul className="py-2 ps-4">
                    <li className="py-1 my-1 tc-text">{t('affResellerP6')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP7')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP8')}</li>
                </ul>
            </div>

            <div className="col-12 pt-3">
                  <h2 className="tc-head fw-semibold py-3">{t('affResellerH4')}</h2>
                  <p className="tc-text my-2">{t('affResellerP9')}</p>
                  <button className="btn btn-lg px-3 send-request-btn">{t('affResellerB3')}</button>  

                  <img className="img-fluid pt-md-5 pt-5 pb-3" src="/images/benefit-girl.jpg" alt="benefit-girl" />
                 
                  <h4 className="tc-title fw-semibold pt-md-4 pt-2">{t('affResellerH2')}</h4>

                  <ul className="py-2 ps-4">
                    <li className="py-1 my-1 tc-text">{t('affResellerP10')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP11')}</li>
                    <li className="py-1 my-1 tc-text">{t('affResellerP12')}</li>
                </ul>
            </div>



          </div>
        </div>
      </section>
    </>
  );
};

export default AffiliatResellerHub;
