import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import "./Videos.css";
import cookies from "js-cookie";

const VideoModal = ({ isOpen, onClose }) => {
  const [videoEmbedId, setVideoEmbedId] = useState("");
  const currentLanguageCode = cookies.get("i18next") || "en";

  useEffect(() => {
    if (currentLanguageCode === "en") {
      setVideoEmbedId("0ldbhscgop");
      
    } else {
      setVideoEmbedId("9s7q2fsdmf");
     
    }
  }, [currentLanguageCode]);

  return (
    <Dialog className="dialog dialog-box" open={isOpen}>
      <div className="video-modal">
        <span className="float-end close" onClick={onClose}>
          <img
            loading="lazy"
            src="/images/close-dialog.svg"
            alt="close"
            style={{ cursor: "pointer" }}
          />
        </span>
        <div
          className="wistia_responsive_padding"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}
          >
            <div
              className={`wistia_embed wistia_async_${videoEmbedId} seo=true videoFoam=true`}
              style={{ height: "100%", position: "relative", width: "100%" }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: "100%",
                  left: 0,
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  top: 0,
                  transition: "opacity 200ms",
                  width: "100%",
                }}
              >
                <img
                  src={`https://fast.wistia.com/embed/medias/${videoEmbedId}/swatch`}
                  style={{ filter: "blur(5px)", height: "100%", objectFit: "contain", width: "100%" }}
                  alt=""
                  aria-hidden="true"
                  onLoad={(e) => (e.target.parentNode.style.opacity = 1)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoModal;
