import React from "react";
import { useTranslation } from "react-i18next";

const ApplyRefund = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="banner py-5">
        <div className="container py-md-5 trems-and-condition">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-8">
              <div className="request-refund">
                <h3 className="mb-4 text-center">
                  <strong>{t("rrh1")}</strong>
                </h3>
                <p>{t("rrp1")}</p>
                <ol>
                  <li className="py-2">
                    <strong>{t("rrh2")}</strong>
                    <ul>
                      <li className="py-2">{t("rrp2")}</li>
                      <li className="py-2">{t("rrp3")}</li>
                    </ul>
                  </li>

                  <li className="py-2">
                    <strong>{t("rrh3")}</strong>
                  </li>
                  <p>{t("rrp4")}</p>
                  <ul>
                    <li className="py-2">{t("rrp5")}</li>
                    <li className="py-2">{t("rrp6")}</li>
                    <p>{t("rrp7")}</p>
                  </ul>

                  <li className="py-2">
                    <strong>{t("rrh4")}</strong>
                  </li>
                  <ul>
                    <li className="py-2">{t("rrp8")}</li>
                    <li className="py-2">{t("rrp9")}</li>
                  </ul>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplyRefund;
