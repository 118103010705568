import React from "react";
import "./Success.css";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const Success = () => {
  const { t } = useTranslation();
  const { order } = JSON.parse(localStorage.getItem("order"));
  console.log("jkdajk", JSON.parse(localStorage.getItem("order")))
  return (
    <div>
      <div className="firework"></div>
      <div className="firework"></div>
      <div className="firework"></div>
      <section className="banner-ImeiBlacklist py-md-5 position-relative">
        <div className="container py-md-5">
          <div className="row justify-content-center p-3 success-section">
            <div className="col-md-12 text-center">
              <img className="img-fluid success-img" src="/images/cele.png" alt="" />
              <h1 className="fw-bold display-5 text-center mt-md-3">
                {t('SucH1')}
              </h1>
              <h5 className="text-center">{t('SucH2')}</h5>
            </div>

            <div className="row justify-content-center pt-3 px-0">
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t('SucH3')}</h5>
                <h5 className="mb-0 fw-bold">{order?.customerEmail}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t('SucH4')}</h5>
                <h5 className="mb-0 fw-bold">{order?.invoice}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-between success-bg p-3 mt-3 rounded">
                <h5 className="mb-0">{t('SucH6')}</h5>
                <h5 className="mb-0 fw-bold">{order?.product?.delivery_time}</h5>
              </div>
              <div className="col-12 col-md-7 d-flex justify-content-center mt-4">
                <Link to={'/contact-us'} style={{ 'color': '#fff', 'text-decoration': 'none' }}><button className="btn rounded  py-3 px-4 me-2 need-support1" type="button">{t('SucB1')}</button></Link>
                <Link to={'/track-order'} style={{ 'color': '#fff', 'text-decoration': 'none' }}><button className="btn rounded py-3 px-4 ms-2 need-supports" type="button">{t('SucB2')}</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Success;
