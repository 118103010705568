import React, { forwardRef } from "react";
import "./contactUs.css";
import ContactUs from "./contactUs";
const ContactPage = forwardRef((props, ref) => {

  return (
    <>
    <section className="banner py-5 ">
      <ContactUs/>
    </section>
    </>
  );
});

export default ContactPage;
