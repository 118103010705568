import requests from "./httpServices";

const BlogServices = {
 

  getAllBlogs: async ( limit ,page) => {
    console.log(limit,page)
    return requests.get(`/blogs?limit=${limit}&page=${page}`);
  },
  getRecentPost: async (id) => {
    return requests.get(`/blogs/recent/${id}`);
  },
  getBlogById: async (id) => {
    return requests.get(`/blogs/${id}`);
  },
 
};

export default BlogServices;
