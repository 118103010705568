import React, { forwardRef, useEffect, useState } from "react";
import "./testimonial.css";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { Link } from "react-router-dom";
import Videos from "../videos/videos";
import Helper from "../NewHowitWork/Helper";

const NewTestimonial = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(cookies.get("i18next") || "en");

  const [trustboxConfig, setTrustboxConfig] = useState({
    website: "",
    trustboxlang: "",
    images: [],
  });

  useEffect(() => {
    // Update trustbox and images based on the language
    if (language === "en") {
      setTrustboxConfig({
        website: "https://www.trustpilot.com/review/www.unlockriver.com",
        trustboxlang: "en-US",
        images: [
          { name: "en1.jpg" },
          { name: "en2.jpg" },
          { name: "en3.jpg" },
          { name: "en4.jpg" },
        ],
      });
    } else {
      setTrustboxConfig({
        website: "https://es.trustpilot.com/review/www.unlockriver.com",
        trustboxlang: "es-ES",
        images: [
          { name: "es1.jpg" },
          { name: "es2.jpg" },
          { name: "es3.jpg" },
          { name: "es4.jpg" },
        ],
      });
    }
  }, [language]);

  useEffect(() => {
    // Listen to language changes
    const currentLang = cookies.get("i18next");
    if (currentLang && currentLang !== language) {
      setLanguage(currentLang);
    }
  }, [language]);

  const trustboxImages = trustboxConfig.images.map((e, index) => (
    <div key={index} className="testimonial-item bg-white col-12 col-md-6">
      <img
        className="img-fluid"
        loading="lazy"
        src={`/images/trustbox/${e.name}`}
        alt=""
      />
    </div>
  ));

  return (
    <div ref={ref} className="testimonials-page">
      <section className="banner pt-5">
        <div className="container pt-md-5 pt-5">
          <div className="row pt-md-5">
            <div className="col-11 col-md-9 bg-white px-md-5 px-4 py-4">
              <p className="text-dark fw-semibold testimonial-text mb-md-4">
                {t("ntmnh1")}
              </p>
              <h4 className="text-dark fw-bold">{t("ntmnh2")}</h4>
              <p className="text-dark mb-md-4 mb-2 testimonial-company">
                {t("ntmnh3")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials py-md-5 pt-5 pb-2">
        <div className="container">
          <h2 className="display-4 text-center">
            {t("TestH11")}{" "}
            <span className="fw-bold text-dark">{t("TestH12")}</span>{" "}
            {t("TestH13")}{" "}
            <span className="fw-bold text-dark">{t("TestH14")}</span>
          </h2>
          <h2 className="text-center display-6">{t("TestH15")}</h2>

          <div className="row mt-5 mx-md-0 mx-2">
            <div className="col-md-12 col-lg-3 trustpilot d-flex flex-column justify-content-center">
              <div>
                <img loading="lazy" src="/images/trustpilot-logo.png" alt="" />
                <div
                  className="trustpilot-widget"
                  data-locale={`${trustboxConfig.trustboxlang}`}
                  data-template-id="5419b6ffb0d04a076446a9af"
                  data-businessunit-id="544819a600006400057b1620"
                  data-style-height="40px"
                  data-style-width="100%"
                  data-theme="dark"
                >
                  <Link to={trustboxConfig.website} target="_blank" rel="noopener">
                    Trustpilot
                  </Link>
                </div>
                <Link
                  className="btn btn-outline-light mt-4 px-5 py-3"
                  to={trustboxConfig.website}
                >
                  {t("TestB1")}
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-9 d-flex flex-wrap px-0 px-md-3 py-3 pt-md-4 pt-lg-0">
              {trustboxImages}
            </div>
          </div>
        </div>
      </section>

      <Videos />
      <Helper title={t("nhiwh6")} />
    </div>
  );
});

export default NewTestimonial;
