import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import requests from "./httpServices";

export const addCheckServiceOrder=async (body)=>{
    try {
      return requests.post(`checkService/addCheckServiceOrder`,body);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(error.response.data.error)
    }
  }
  export const getFreeSampale=async (body)=>{
    try {
      return requests.post(`checkService/getFreeSampale`,body);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(error.response.data.error)
    }
  }

  export const createPaymentIntent=async (body)=>{
    try {
      return requests.post(`checkService/createPaymentIntent`,body);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error(error.response.data.error)
    }
  }
