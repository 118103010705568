import React from "react";
import { useTranslation } from "react-i18next";
const Troubleshoot = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className=" troubleshoot-page affiliate  ">
        <div className="container py-md-2 pt-2">
          <div className="row pt-md-5 justify-content-center">
            <div className="col-12 col-md-10">
              <div className="unlocking-troubleshooting">
                <h4 className="py-md-2 py-3 tg-head fw-bold">{t("tsh1")}</h4>
                 <p className="tg-text my-md-0 my-2">{t("tsp1")}</p>
              </div>
            </div>
          </div>
          <div className="row pt-md-5 justify-content-center">
          <div className="col-12 col-md-3 trouble-tabs">
              <a className="trouble-tab" href="#general-tips" >General Tips</a>
              <a className="trouble-tab" href="#android-device" >Android Devices</a>
              <a className="trouble-tab" href="#ios-device" >iOS Devices (iPhone):</a>


            </div>
            <div className="col-12 col-md-7 px-4 py-3">
                <h5 id="general-tips" className="tc-title fw-bold">{t("tsh2")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp2")}</li>
                    <li className="pb-3 m-0 tg-text">{t("tsp3")} </li>
                  </ul>

                <h5 id="android-device" className="tc-title fw-bold">{t("tsh3")}</h5>
                  <p  className="tg-text my-2">{t("tsp4")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp5")}</li>
                    <li className="pb-3 m-0 tg-text">{t("tsp6")}</li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp7")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp8")}</li>
                    <li className="pb-3 m-0 tg-text">{t("tsp9")}</li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp10")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp11")}</li>
                    <li className="pb-3 m-0 tg-text">{t("tsp12")}</li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp13")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp14")}</li>
                    <li className="pb-3 m-0 tg-text">{t("tsp15")}</li>
                  </ul>

                
                <h5 id="ios-device" className="tc-title fw-bold">{t("tsh4")}</h5>
                  <p  className="tg-text my-2">{t("tsp19")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">{t("tsp20")}</li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp21")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp22")}
                    </li>
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp23")}
                    </li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp24")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp25")}
                    </li>
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp26")}
                    </li>
                  </ul>
                  <p  className="tg-text my-2">{t("tsp27")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp28")}
                    </li>
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp29")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp30")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp31")}
                    </li>
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp32")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp33")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp34")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp35")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp36")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp40")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp41")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp42")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp43")}
                    </li>
                    <li className="pb-3 m-0 tg-text">
                    {t("tsp44")}
                    </li>
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp45")}
                    </li>
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp46")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp47")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp48")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp49")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp50")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp51")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp52")}
                    </li>
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp53")}
                    </li>
                  </ul>

                  <p  className="tg-text my-2">{t("tsp54")}</p>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="pb-3 m-0 tg-text">
                    {t("tsp55")}
                    </li>
                  </ul>
                <h4 className="tc-title fw-bold">{t("tsh5")}</h4>
                <p  className="pb-3 m-0 tg-text">
                {t("tsp56")}
                </p>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default Troubleshoot;
