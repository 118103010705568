import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import BlogServices from "../../_services/blogService";
import "./blogs.css";
import {
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share";
import {
  WhatsappIcon,
  LinkedinIcon,
  XIcon,
  TelegramIcon,
  FacebookIcon,
  EmailIcon,
} from "react-share";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const ShareButtons = ({ title }) => {
  return (
    <div className="share-social">
      <TwitterShareButton url={window.location.href} title={title}>
        <XIcon size={40} />
      </TwitterShareButton>
      <WhatsappShareButton url={window.location.href} title={title}>
        <WhatsappIcon size={40} />
      </WhatsappShareButton>
      <LinkedinShareButton url={window.location.href} title={title}>
        <LinkedinIcon size={40} />
      </LinkedinShareButton>
      <TelegramShareButton url={window.location.href} title={title}>
        <TelegramIcon size={40} />
      </TelegramShareButton>
      <FacebookShareButton url={window.location.href} title={title}>
        <FacebookIcon size={40} />
      </FacebookShareButton>
      <EmailShareButton url={window.location.href} title={title}>
        <EmailIcon size={40} />
      </EmailShareButton>
    </div>
  );
};
const BlogDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [share, setShare] = useState(false);
  const [blog, setBlog] = useState([]);
  const [recentBlog, setRecentBlogs] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await BlogServices.getBlogById(id);
        setBlog(res);
        console.log(JSON.parse(res.fields));
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    const fetchRecentBlog = async () => {
      try {
        const res = await BlogServices.getRecentPost(id);
        console.log(res);
        setRecentBlogs(res);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchRecentBlog();
    fetchData();
  }, [id]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "short", // Short weekday name (e.g., "Tue")
      month: "short", // Short month name (e.g., "Sep")
      day: "2-digit", // Two-digit day of the month (e.g., "22")
      year: "numeric", // Full numeric year (e.g., "2021")
    };
    return date.toLocaleDateString("en-US", options);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const linkShare = () => {
    setShare(!share); // Toggle the state between true and false
  };
  return (
    <>
      <section className=" blog-details">
        <div className="container py-md-2 pt-2">
          <div className="row pt-md-5">
            <div className="col-12 px-4 py-3 blog-col">
              <p className=" pt-3">
                <Link
                  to={"/blogs"}
                  className="breadcrum fw-bold text-decoration-none"
                >
                  Blog /{" "}
                </Link>
                {blog?.blogTitle}{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8 mb-3 mb-md-5 px-3">
              <div className="featured-img">
                {blog.imgUrl ? (
                  <img
                    className="img-fluid"
                    src={`${process.env.REACT_APP_API_URL1}/${blog?.imgUrl}`}
                    alt={blog?.blogTitle}
                  />
                ) : (
                  <Skeleton
                    sx={{ height: 375 }}
                    animation="wave"
                    variant="rectangular"
                  />
                )}
              </div>
              <div className="d-flex justify-content-between pt-md-5 pt-3">
                <div>
                  <p className="dates">{formatDate(blog?.createdAt)}</p>
                </div>
                <div>
                  <Link
                    className="share text-decoration-none text-dark"
                    onClick={handleClickOpen}
                  >
                    {" "}
                    <i className="fa fa-share"></i> {t("blog6")}
                  </Link>
                  {/* {share && (
                    <ShareButtons
                      url={window.location.url}
                      title={blog?.blogTitle}
                    />
                  )} */}
                </div>
              </div>
              <h1 className="py-3 display-5 fw-semibold">{blog?.blogTitle}</h1>

              <div className="d-flex justify-content-between ">
                <p className="estimated-time">
                  Estimated time to complete: 11 mins
                </p>
                <p className="author"> Author:{blog?.blogAuthor}</p>
              </div>
              {blog.fields &&
                JSON.parse(blog.fields).map((field, index) => (
                  <div key={index}>
                    <p className="blog-detail-description mt-md-5 mt-2">
                      <strong>{field.heading}</strong>
                      <br />
                      {field.description}{" "}
                    </p>
                    {blog.blogVideoLink && index === 0 && (
                       <div className="player-wrapper">
                       <ReactPlayer
                         className="react-player"
                         url={`${blog.blogVideoLink}`}
                         width="100%"
                         height="100%"
                         controls={true}
                       />
                     </div>
                    )}
                  </div>
                ))}
              <div></div>
            </div>
            <div className="col-12 col-md-4 col-lg-4 mb-3 mb-md-5 px-md-3 px-0">
              <div className="related bg-white shadow p-4">
                <h2 className="pt-0 fw-semibold">{t("blog5")}</h2>
                <div className="row pt-3">
                  {recentBlog &&
                    recentBlog.map((blog, index) => (
                      <div className="col-12 mb-4" key={index}>
                        <Link
                          to={`/blog-details/${blog._id}`}
                          onClick={scrollToTop}
                        >
                          {blog.imgUrl ? (
                            <img
                              className="img-fluid"
                              src={`${process.env.REACT_APP_API_URL1}/${blog?.imgUrl}`}
                              alt={blog?.blogTitle}
                            />
                          ) : (
                            <Skeleton
                              sx={{ height: 200 }}
                              animation="wave"
                              variant="rectangular"
                            />
                          )}
                          <p className="date">{formatDate(blog?.createdAt)}</p>
                          <h3 className="blog-title py-3 fw-semibold">
                            {blog?.blogTitle}
                          </h3>
                          <p className="blog-description">
                            {JSON.parse(blog.fields)[0].description}
                          </p>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog className="social-popup" open={open} onClose={handleClose}>
        <DialogContent>
          <ShareButtons url={window.location.url} title={blog?.blogTitle} />
        </DialogContent>
        {/* <DialogActions>
          <Button className="" onClick={handleClose}>✖️</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default BlogDetails;
