import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./newHowitWork.css";
import VideoModal from "../../shared/videos/VideoModal";
import Helper from "./Helper";
const NewHowitWork = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("openModal");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("openModal");
  };
  return (
    <>
      <section className="banner py-3 About-page">
        <div className="container py-md-2 pt-5">
          <div className="row  justify-content-center align-items-center">
            <div className="col-12 col-md-6 px-md-5 col-howitwork ">
              <h1 className="fw-bold display-5">
                {t("nhiwh1")}
              </h1>
              <p className="imeiblacklist balance mb-md-3">
              {t("nhiwp1")}
              </p>
              <button
                className="btn howitwork-btn fw-bold mb-2"
                onClick={openModal}
              >
                {" "}
                <i className="fa fa-play me-2"></i> {t("nhiwb1")}
              </button>
              <VideoModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
            <div className="col-12 col-md-6 px-4 text-center col-howitwork">
            <img
                className="img-fluid p-4 p-md-0"
                src="/images/Group.png"
                alt="about-mission"
              />
           </div>
        </div>
        </div>
      </section>
      
      <section className="step1">
        <div className="container p-3 py-md-2">
          <div className="row py-md-5 py-4 justify-content-center">
            <div className="col-12 col-md-6 px-0">
              <h2 className="text-center three-phase fw-semibold display-5">
              {t("nhiwh2")}
              </h2>
            </div>
          </div>
          <div className="row justify-content-center px-md-4 d-flex py-md-0 align-items-center">
            <div className="col-12 col-md-6 px-md-5">
              <h5 className="fw-semibold">{t("nhiws1")} 1</h5>
              <h2 className="display-5 fw-bold">{t("nhiwh3")}</h2>
              <p className="py-2 ps-0 pe-md-3">
              {t("nhiwp2")}
              </p>
            </div>
            <div className="col-10 col-md-6 text-center py-4 py-md-0">
              <img
                className="img-fluid"
                src="/images/step1.png"
                alt="about-story" width={'500'}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="step2">
        <div className="container p-3 py-md-0">
          <div className="row justify-content-center d-flex align-items-center px-md-5">
            <div className="col-10 col-md-6 text-center py-4 py-md-0 mission-img">
              <img
                className="img-fluid p-4 p-md-0"
                src="/images/step2.png"
                alt="about-mission" width={'500'}
              />
            </div>
            <div className="col-12 col-md-6 px-md-5 mission-col">
              <h5 className="fw-semibold">{t("nhiws1")} 2</h5>
              <h2 className="display-5 fw-bold">{t("nhiwh4")}</h2>
              <p className="py-2 ps-0 pe-md-3">
              {t("nhiwp3")}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="step3">
        <div className="container p-3 py-md-5">
          <div className="row justify-content-center d-flex  align-items-center px-md-5">
            <div className="col-12 col-md-6 px-md-5 mission-col">
              <h5 className="fw-semibold">{t("nhiws1")} 3</h5>
              <h2 className="display-5 fw-bold">{t("nhiwh5")}</h2>
              <p className="py-2 ps-0 pe-md-3">
              {t("nhiwp4")}
              </p>
            </div>
            <div className="col-10 col-md-6 text-center py-4 py-md-0 pb-md-4 mission-img">
              <img
                className="img-fluid p-4 p-md-0"
                src="/images/step3.png"
                alt="about-mission" width={'500'}
              />
            </div>
          </div>
        </div>
      </section>
      <Helper title={t("nhiwh6")} />
    </>
  );
};

export default NewHowitWork;
